import '@assets/chrome-bug.css'
import '@assets/main.css'
import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import * as analytics from '@lib/analytics'
import { IsMutedProvider } from '@lib/useIsMuted'
import { PromptToInstallProvider } from '@lib/usePromptToInstall'
import * as snippet from '@segment/snippet'
import Cookies from 'js-cookie'
import 'keen-slider/keen-slider.min.css'
import { SessionProvider } from 'next-auth/react'
import type { AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'
import 'swiper/css'
import 'swiper/css/virtual'

Router.events.on('routeChangeComplete', (path) => {
  if (path.startsWith('/product/')) {
    const productSlug = path.replace(/^.*\/product\//, '').replace(/\/.*$/, '')
    analytics.page(path, { productSlug })
  } else analytics.page(path)
})

const Noop: FC = ({ children }) => <>{children}</>

function renderSnippet() {
  const opts = {
    apiKey:
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ? 'WZ9aXnNWJgTtOkSmEFm2Mzs6a9jDUwNA'
        : 'bFC4lFquR0QMtVAhhx9VAWcVLxI6S2EL',
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: false,
  }

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts)
  }

  return snippet.min(opts)
}

export default function MyApp({
  Component,
  pageProps: { session, theme, ...pageProps },
}: AppProps) {
  const Layout = (Component as any).Layout || Noop
  const router = useRouter()

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  useEffect(() => {
    if (router.query.shopcatSessionId) {
      Cookies.set('shopcatSessionId', router.query.shopcatSessionId)
    }
  }, [router.query.shopcatSessionId])

  const isAppRoute = /^\/app/.test(router.pathname)

  return (
    <>
      <Head />
      <Script
        id="segment-script"
        dangerouslySetInnerHTML={{ __html: renderSnippet() }}
      />

      {process.env.NEXT_PUBLIC_VERCEL_ENV && (
        <Script src="/initServiceWorker.js" />
      )}

      {isAppRoute ? (
        <SessionProvider session={session}>
          <ManagedUIContext theme={theme}>
            <Layout pageProps={pageProps}>
              <Component {...pageProps} />
            </Layout>
          </ManagedUIContext>
        </SessionProvider>
      ) : (
        <ManagedUIContext theme={theme}>
          <IsMutedProvider>
            <PromptToInstallProvider>
              <Layout pageProps={pageProps}>
                <Component {...pageProps} />
              </Layout>
            </PromptToInstallProvider>
          </IsMutedProvider>
        </ManagedUIContext>
      )}
    </>
  )
}
