import { isWatchModeIframe } from '@components/sites/product/helpers'
import Cookies from 'js-cookie'

const isIframe = isWatchModeIframe()

declare let window: any

const getSessionId = () => {
  const sessionId = Cookies.get('shopcatSessionId')
  if (sessionId) {
    return sessionId
  }

  const params = new URLSearchParams(window.location.search)
  if (params.has('shopcatSessionId')) {
    return params.get('shopcatSessionId')
  }
}

export const track = (event: string, properties?: any) =>
  window.analytics.track(event, {
    ...properties,
    isIframe,
    domain: window.location.hostname,
    source: 'storefront',
    sessionId: getSessionId(),
  })

export const page = (path: string, properties?: any) =>
  window.analytics.page(path, {
    ...properties,
    isIframe,
    domain: window.location.hostname,
    source: 'storefront',
    sessionId: getSessionId(),
  })
