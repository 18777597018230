import s from './LoadingDots.module.css'

const LoadingDots: React.FC = () => {
  return (
    <span className={s.root}>
      <span className={s.dot} key={`dot_1`} />
      <span className={s.dot} key={`dot_2`} />
      <span className={s.dot} key={`dot_3`} />
    </span>
  )
}

export const LoadingDotsFullPage: React.FC = () => {
  return (
    <div className="absolute z-50 flex h-screen w-full items-center justify-center bg-white  text-amber-600">
      <LoadingDots />
    </div>
  )
}

export default LoadingDots
