import { getCookie, setCookie } from 'cookies-next'
import React, { createContext, useCallback, useContext } from 'react'
import { Children, IsMutedCtx } from './types'

const defaultValue =
  JSON.parse((getCookie('isVideoMuted') as string) || JSON.stringify(false)) ||
  false

const IsMuted = createContext<IsMutedCtx>(defaultValue)

export function IsMutedProvider(props: Children) {
  const [muted, setMuted] = React.useState<boolean>(defaultValue)

  const setIsMuted = useCallback((isMuted: boolean) => {
    setMuted(isMuted)
    setCookie('isVideoMuted', isMuted)
  }, [])

  return (
    <IsMuted.Provider value={{ isMuted: muted, setIsMuted }}>
      {props.children}
    </IsMuted.Provider>
  )
}

export function useIsMuted() {
  const ctx = useContext(IsMuted)
  if (!ctx) {
    throw new Error('Cannot use useIsMuted() outside <IsMutedProvider />')
  }
  return ctx
}
