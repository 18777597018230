import type { Product, ProductVariant } from '@commerce/types/product'
export type SelectedOptions = Record<string, string | null>
import { Dispatch, SetStateAction } from 'react'

declare let window: any

export function getProductVariant(product: Product, opts: SelectedOptions) {
  const variant = product.variants.find((variant) => {
    return Object.entries(opts).every(([key, value]) =>
      variant.options.find((option) => {
        if (
          option.__typename === 'MultipleChoiceOption' &&
          option.displayName.toLowerCase() === key.toLowerCase()
        ) {
          return option.values.find((v) => v.label.toLowerCase() === value)
        }
      })
    )
  })
  return variant
}

export function selectDefaultOptionFromProduct(
  product: Product,
  updater: Dispatch<SetStateAction<SelectedOptions>>
) {
  // Selects the default option
  product.variants[0]?.options?.forEach((v) => {
    updater((choices) => ({
      ...choices,
      [v.displayName.toLowerCase()]: v.values[0].label.toLowerCase(),
    }))
  })
}

export function getDefaultOptionFromProduct(product: Product) {
  const defaultOptions: Record<string, any> = {}
  // Selects the default option
  product.variants[0]?.options?.forEach((v) => {
    defaultOptions[v.displayName.toLowerCase()] =
      v.values[0].label.toLowerCase()
  })

  return defaultOptions
}

export const isWatchModeIframe = () => {
  return typeof window !== 'undefined' ? (window.location !== window.parent.location) : undefined;
}

export const getDefaultProductVariant = (product: Product): ProductVariant | undefined => {
  return getProductVariant(product, getDefaultOptionFromProduct(product))
}

export const getVariantId = (id: string | number) => {
  const splittedArray = String(id).split('/')
  return splittedArray[splittedArray.length - 1]
}

export const getProductTags = (tags: String[] | undefined) => {
  // Converts String[] of tags into Object with tag names as keys and tag value as values
  return tags?.reduce((acc: any, el: any) => {
    const arr = el.split(':')
    acc[arr[0].replace('shopcat-', '')] = arr[1];
    return acc;
  }, {})
}
