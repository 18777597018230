export const defaultThemeColor = '#fed7aa'

export const themeColorByThemeId = {
    'theme-red': '#fecdd3',
    'theme-pink': '#fbcfe8',
    'theme-pink-purple': '#f5d0fe',
    'theme-purple': '#e9d5ff',
    'theme-indigo': '#cbd4fb',
    'theme-indigo-light': '#ddd6fe',
    'theme-dark-blue': '#c7d2fe',
    'theme-blue': '#bfdbfe',
    'theme-azure-light': '#bae6fd',
    'theme-teal': '#aaf0f4',
    'theme-teal-azure': '#a5f3fc',
    'theme-island': '#99f6e4',
    'theme-submarine': '#a7f3d0',
    'theme-emerald': '#bbf7d0',
    'theme-chartreuse': '#d9f99d',
    'theme-saffron': '#fef08a',
    'theme-tangerine': '#fde68a',
    'theme-coral': '#fecaca',
    'theme-crimson': '#f8d2d6',
}
